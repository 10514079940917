"use strict";

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function showNewsletter() {
    $('.newsletter').css("display", "flex").hide().fadeIn();
}

function signUpnewsletter() {
    $('#message-err').html('');
    $('#message-err').removeClass('has-err');
    $('#newsletter-email').removeClass('err');

    var email = $('#newsletter-email').val();

    if (validateEmail(email)) {
        $.ajax("/newslettersub", {
            data: JSON.stringify({ "email": email }),
            contentType: 'application/json',
            type: 'POST',
            success: function success(data) {
                $('.newsletter .sign-up').fadeOut();
                $('.newsletter .completed').fadeIn();
                $('.newsletter  .completed').css("display", "block").hide().fadeIn();
                setCookie('newsletter-closed', true);
            },
            error: function error(err) {
                $('#message-err').addClass('has-err');
                $('#message-err').html('En ukjent feil oppstod.');
            }
        });
    } else {
        $('#message-err').html('Feil ved epost');
        $('#newsletter-email').addClass('err');
        $('#message-err').addClass('has-err');
    }
}

function closeNewsletter() {
    $('.newsletter').fadeOut();

    setCookie('newsletter-closed', true);
}

var newsletterCookie = getCookie('newsletter-closed');
if (!newsletterCookie) {
    showNewsletter();
}

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}